import React, { Component } from "react";
import { withPrefix } from "gatsby";
import { Helmet } from "react-helmet";
import debounce from "lodash.debounce";
import logoBlackWithBubble from "../images/tuzag_black_with_message_bubble.svg";
// import logoWhiteWithBubble from "../images/tuzag_white_with_message_bubble.svg";
// import tuzagTCS from "../images/tuzagTCS.svg";
import tuzagInside from "../images/tuzagInside.svg";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Intercom from "react-intercom";
import ReactGA from "react-ga";
import ReCAPTCHA from "react-google-recaptcha";
import { Element } from "react-scroll";
import solutions from "../images/solutions.svg";
import processDiagram from "../images/process.svg";

const tailor = async (messageID, data = {}) => {
	let apiURL;

	if (process.env.GATSBY_REACT_APP_ENV === "staging") {
		apiURL = "https://api.staging.tuzagdesign.com/tailor/message";
	} else {
		apiURL =
			process.env.NODE_ENV === "development"
				? "http://localhost:3000/tailor/message"
				: "https://api.v2.tuzagdesign.com/tailor/message";
	}

	data.apiKey = "nRvtIpRUNsxAuTUQ3w8DnRvtIpRUNsxAuTUQ3w8D";
	data.returnElementSlugs = true;

	const unParsedData = await fetch(`${apiURL}/${messageID}`, {
		method: "POST",
		credentials: "include",
		mode: "cors",
		headers: {
			"Content-Type": "application/json"
		},
		body: JSON.stringify(data)
	});

	return await unParsedData.json();
};

export default class Home extends Component {
	state = { scrollTop: 0 };

	componentDidMount = async () => {
		ReactGA.initialize("UA-67845484-11");
		ReactGA.set({
			page: window.location.pathname + window.location.search
		});
		ReactGA.pageview(window.location.pathname + window.location.search);

		window.addEventListener("scroll", this.setScrollDataInState);

		this.setState(
			{
				tailorData: await tailor("4235,4236,4237,4238,4239,4240,4241,4242,4234")
			},
			() => {
				console.log(this.state.tailorData);
			}
		);
	};

	tailorData(key, mid, raw = false) {
		try {
			if (this.state.tailorData) {
				if (raw) {
					return this.state.tailorData[mid][key];
				}
				return (
					<span
						dangerouslySetInnerHTML={{
							__html: this.state.tailorData[mid][key]
						}}
					/>
				);
			} else {
				return <span />;
			}
		} catch (e) {
			//do nothing
		}
	}

	setScrollDataInState = debounce(() => {
		this.setState({
			scrollTop: document.body.scrollTop || document.documentElement.scrollTop
		});
	}, 50);

	onChange = value => {
		this.setState({ recaptcha: value });
	};

	render() {
		return (
			<div>
				<Helmet>
					{/*<script src={withPrefix("static/theme-js/jquery-1.11.3.min.js")} />*/}
					<script src={withPrefix("static/theme-js/bootstrap.js")} />
					<script src={withPrefix("static/theme-js/owl.carousel.min.js")} />
					<script src={withPrefix("static/theme-js/jquery.scrollTo-min.js")} />
					<script
						src={withPrefix("static/theme-js/jquery.magnific-popup.min.js")}
					/>
					<script src={withPrefix("static/theme-js/jquery.nav.js")} />
					<script src={withPrefix("static/theme-js/wow.js")} />
					<script src={withPrefix("static/theme-js/jquery.vegas.js")} />
					<script src={withPrefix("static/theme-js/plugins.js")} />
					<script src={withPrefix("static/theme-js/custom.js")} />
					<link
						href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
						rel="stylesheet"
						integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"
						crossOrigin="anonymous"
					/>
					<link
						href="https://fonts.googleapis.com/css?family=Biryani:400,700|Cabin:700&display=swap"
						rel="stylesheet"
					/>
					<link
						href="https://assets.calendly.com/assets/external/widget.css"
						rel="stylesheet"
					/>
					<script
						src="https://assets.calendly.com/assets/external/widget.js"
						type="text/javascript"
					/>
				</Helmet>
				{!this.state.tailorData ? (
					<div className="container text-center">
						<img
							src={logoBlackWithBubble}
							alt="tuzag logo"
							style={{ marginTop: 40 }}
						/>
						<h1>
							<i
								className="fa fa-circle-o-notch fa-spin"
								aria-hidden="true"
								style={{ fontSize: 72, marginTop: 50 }}
							/>
						</h1>
					</div>
				) : (
					<div>
						<Navbar
							tailorData={this.state.tailorData}
							scrollTop={this.state.scrollTop}
						/>
						<Element name="home" />
						<section
							className="home-section"
							id="about"
							style={{ paddingTop: 140 }}
						>
							<div className="home-section-overlay" />

							<div className="container">
								<div className="row">
									<div className="col-md-6">
										<img
											src={tuzagInside}
											style={{ width: "100%", maxWidth: 600 }}
											alt="tuzag Inside logo"
										/>
									</div>
									<div
										className="col-md-8 col-sm-offset-4"
										style={{ marginTop: 40 }}
									>
										<h4 style={{ lineHeight: 1.4, fontWeight: 400 }}>
											{this.tailorData("introduction", 4236)}
										</h4>
									</div>
								</div>
							</div>
						</section>
						<Element name="solutions" />
						<section className="section-white">
							<div className="container">
								<div className="row">
									<div className="col-md-12  text-center">
										<h2 className="section-title" style={{ color: "#7faf41" }}>
											{this.tailorData("headline", 4237)}
										</h2>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12 d-none d-sm-block">
										<p className="section-subtitle" style={{ columnCount: 2 }}>
											{this.tailorData("introduction", 4237)}
										</p>
									</div>
									<div className="col-sm-12 d-block d-sm-none">
										<p className="section-subtitle">
											{this.tailorData("introduction", 4237)}
										</p>
									</div>
								</div>
								<div className="row">
									<div className="col-md-4">
										<h3 style={{ color: "#7faf41" }}>
											{this.tailorData("subhead", 4237)}
										</h3>
										<p>{this.tailorData("body", 4237)}</p>
									</div>
									<div className="col">
										<img
											src={solutions}
											alt="solutions diagram"
											style={{ width: "100%" }}
										/>
									</div>
								</div>
							</div>
						</section>
						<Element name="process" />
						<section
							className="section-white"
							style={{ background: "#F0F0F0" }}
						>
							<div className="container">
								<div className="row">
									<div className="col text-center">
										<h2>{this.tailorData("headline", 4238)}</h2>
									</div>
								</div>
								<div className="row">
									<div className="col-md text-center">
										<img
											src={processDiagram}
											alt="process diagram"
											style={{ width: "100%", maxWidth: 600 }}
										/>
									</div>
									<div className="col-md" style={{ paddingTop: 15 }}>
										<p>{this.tailorData("body", 4238)}</p>
									</div>
								</div>
							</div>
						</section>
						{/*<Element name="demo" />*/}
						<Element name="about" />
						<section
							className="section-white"
							style={{ backgroundColor: "#C6C6C6" }}
						>
							<div className="container">
								<div className="row">
									<div className="col text-center">
										<h2>{this.tailorData("headline", 4241)}</h2>
									</div>
								</div>
								<div className="row">
									<div
										className="col d-sm-block d-md-none"
										style={{ alignSelf: "center" }}
									>
										<a
											href="https://wearetuzag.com"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												src={logoBlackWithBubble}
												alt="tuzag logo"
												style={{ width: "100%" }}
											/>
										</a>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<p>{this.tailorData("introduction", 4241)}</p>
									</div>
									<div
										className="col d-none d-md-block d-lg-block d-xl-block"
										style={{ alignSelf: "center" }}
									>
										<a
											href="https://wearetuzag.com"
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												src={logoBlackWithBubble}
												alt="tuzag logo"
												style={{ width: "100%" }}
											/>
										</a>
									</div>
								</div>
								<div className="row">
									<div className="col text-center">
										<a
											className="btn btn-default"
											style={{
												borderColor: "black",
												paddingTop: 15,
												paddingBottom: 15,
												paddingLeft: 50,
												paddingRight: 50,
												width: "100%",
												maxWidth: 250,
												backgroundColor: "transparent"
											}}
											type="submit"
											href="https://wearetuzag.com"
											target="_blank"
											rel="noopener noreferrer"
										>
											{this.tailorData("buttonLabel", 4241)}
										</a>
									</div>
								</div>
							</div>
						</section>
						<Element name="contact" />
						<section className="section-white" id="contact">
							<div className="container">
								<div className="row">
									<div className="col-md-10 col-md-offset-1 text-center margin-bottom-30">
										<h2 className="section-title">
											{this.tailorData("headline", 4242)}
										</h2>
									</div>
								</div>

								<div className="margin-20">
									{this.state.contactFormSubmitted ? (
										<div className="text-center">
											<p
												className="contact_success_box"
												style={{ color: "black", fontStyle: "normal" }}
											>
												{this.tailorData("postSendMessage", 4233)}
											</p>
										</div>
									) : (
										<form
											id="contact-form"
											className="row contact"
											onSubmit={async evt => {
												evt.preventDefault();
												const formData = {
													name: document.getElementById("contact-names").value,
													email: document.getElementById("contact-email").value,
													phone: document.getElementById("contact-phone").value,
													message: document.getElementById("contact-comment")
														.value,
													recaptchaToken: this.state.recaptcha
												};

												let apiURL;

												if (process.env.GATSBY_REACT_APP_ENV === "staging") {
													apiURL =
														"https://api.staging.tuzagdesign.com/marketingSiteMessage";
												} else {
													apiURL =
														process.env.NODE_ENV === "development"
															? "http://localhost:3000/marketingSiteMessage"
															: "https://api.v2.tuzagdesign.com/marketingSiteMessage";
												}

												fetch(apiURL, {
													method: "POST",
													credentials: "include",
													mode: "cors",
													headers: {
														"Content-Type": "application/json"
													},
													body: JSON.stringify(formData)
												});

												this.setState({ contactFormSubmitted: true });
											}}
										>
											<div className="col-md">
												<div className="row">
													<div
														className="col-xs-2 text-right"
														style={{
															paddingRight: 0,
															paddingTop: 18
														}}
													>
														<label
															htmlFor="contact-names"
															style={{ display: "block" }}
														>
															{this.tailorData("nameLabel", 4242, true)}
														</label>
													</div>
													<div className="col-xs-10">
														<input
															className="contact-input"
															placeholder={this.tailorData(
																"namePlaceholder",
																4242,
																true
															)}
															type="text"
															id="contact-names"
														/>
													</div>
												</div>

												<div className="row">
													<div
														className="col-xs-2 text-right"
														style={{
															paddingRight: 0,
															paddingTop: 18
														}}
													>
														<label
															htmlFor="contact-email"
															style={{ display: "block" }}
														>
															{this.tailorData("emailLabel", 4242)}
														</label>
													</div>
													<div className="col-xs-10">
														<input
															className="contact-input"
															placeholder={this.tailorData(
																"emailPlaceholder",
																4242,
																true
															)}
															type="text"
															id="contact-email"
														/>
													</div>
												</div>

												<div className="row">
													<div
														className="col-xs-2 text-right"
														style={{
															paddingRight: 0,
															paddingTop: 18
														}}
													>
														<label
															htmlFor="contact-phone"
															style={{ display: "block" }}
														>
															{this.tailorData("phoneLabel", 4242)}
														</label>
													</div>
													<div className="col-xs-10">
														<input
															className="contact-input"
															placeholder={this.tailorData(
																"phonePlaceholder",
																4242,
																true
															)}
															type="text"
															id="contact-phone"
														/>
													</div>
												</div>

												<div className="row">
													<div
														className="col-xs-2 text-right"
														style={{
															paddingRight: 0,
															paddingTop: 18
														}}
													>
														<label style={{ display: "block" }}>
															{this.tailorData("meetLabel", 4242)}
														</label>
													</div>
													<div className="col-xs-10">
														<button
															className="btn btn-default"
															style={{
																width: "100%",
																borderColor: "gray",
																paddingTop: 15,
																paddingBottom: 15,
																textAlign: "left",
																fontWeight: 400,
																color: "gray",
																fontFamily: "'Open Sans', sans-serif"
															}}
															ref={el => {
																if (el) {
																	el.style.setProperty(
																		"font-size",
																		"14px",
																		"important"
																	);
																}
															}}
															onClick={() => {
																window.Calendly.initPopupWidget({
																	url: "https://calendly.com/neal-sofian"
																});
																return false;
															}}
															type="button"
														>
															{this.tailorData("meetText", 4242, true)}
														</button>
													</div>
												</div>
											</div>

											<div className="col-md">
												<div
													className="d-md-none d-lg-none d-xl-none"
													style={{ marginTop: 20 }}
												/>

												<div className="row">
													<div
														className="col-xs-2 text-right"
														style={{
															paddingRight: 0,
															paddingTop: 18,
															paddingLeft: 0
														}}
													>
														<label
															htmlFor="contact-comment"
															style={{ display: "block" }}
														>
															{this.tailorData("messageLabel", 4242)}
														</label>
													</div>
													<div className="col-xs-10">
														<textarea
															className="contact-commnent"
															rows="2"
															cols="20"
															name="contact_message"
															placeholder={this.tailorData(
																"messagePlaceholder",
																4242,
																true
															)}
															id="contact-comment"
														/>
													</div>
												</div>
												<div className="row">
													<div className="col-sm-10 col-sm-offset-2 col-xs-12">
														{this.state.recaptcha ? (
															<button
																className="btn btn-default"
																style={{
																	borderColor: "#390A4E",
																	paddingTop: 15,
																	paddingBottom: 15,
																	paddingLeft: 50,
																	paddingRight: 50,
																	width: "100%"
																}}
																type="submit"
															>
																{this.tailorData("buttonLabel", 4242)}
															</button>
														) : (
															<ReCAPTCHA
																sitekey="6Lf1GKoUAAAAAMveW4W4SgcKJVPvHf4FXZUQBzM4"
																className="recaptcha"
																onChange={this.onChange}
															/>
														)}
													</div>
												</div>
											</div>
										</form>
									)}
								</div>
							</div>
						</section>
						<Footer tailorData={this.state.tailorData} />
					</div>
				)}
				<Intercom appID="a48abriy" />
			</div>
		);
	}
}
